import React from "react"
import { Global, css, jsx } from "@emotion/core"
import Layout from "../components/layout"

import ContentPreview from "../components/ContentPreview"

export default () => {
  return (
    <>
      <Layout>
        <ContentPreview />
      </Layout>
    </>
  )
}
